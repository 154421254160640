<template>
  <Steps :step="1" />
  <div class="mt-5">
    <Form :actions="false" :service="service" @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-md-3">
          <FormField :service="service" field="number" :label="$t('An invoice for payment №')" />
        </div>
        <div class="col-md-3">
          <FormField :service="service" field="date" :label="$t('from')" type="calendar" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <FormField :service="service" field="provider" type="select" :options="providerList" />
        </div>
        <div class="col-md-10">
          <div class="p-fluid mt-4 mb-3">
            <div class="p-field">
              <AutoComplete
                v-model="service.providerValue"
                :suggestions="filteredDadata"
                placeholder="ИП Иванов Иван Иванович"
                @input="getDadata(service.providerValue)"
                @complete="searchDadata($event)"
                field="value"
                modelValue="value"
                :class="service.errors && service.errors.providerValue ? 'p-invalid is-invalid' : ''"
              >
                <template #item="slotProps">
                  <div>{{ slotProps.item.value }}</div>
                  <div class="text-muted fs-7">
                    {{ slotProps.item.data.address.value }}
                  </div>
                </template>
              </AutoComplete>
              <div v-if="service.errors && service.errors.providerValue" class="invalid-feedback">
                {{ service.errors ? service.errors.providerValue : null }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <FormField :service="service" field="сustomer" type="select" :options="customerList" />
        </div>
        <div class="col-md-10">
          <div class="p-fluid mt-4 mb-3">
            <div class="p-field">
              <AutoComplete
                v-model="service.сustomerValue"
                :suggestions="filteredDadata"
                placeholder="ООО СИРИУС"
                @input="getDadata(service.сustomerValue)"
                @complete="searchDadata($event)"
                field="value"
                modelValue="value"
                :class="service.errors && service.errors.сustomerValue ? 'p-invalid is-invalid' : ''"
              >
                <template #item="slotProps">
                  <div>{{ slotProps.item.value }}</div>
                  <div class="text-muted fs-7">
                    {{ slotProps.item.data.address.value }}
                  </div>
                </template>
              </AutoComplete>
              <div v-if="service.errors && service.errors.сustomerValue" class="invalid-feedback">
                {{ service.errors ? service.errors.сustomerValue : null }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <FormField
          :service="service"
          field="base"
          :label="$t('Base')"
          type="textarea"
          margin="0"
          :disabled="service.isBase == 1"
          :placeholder="$t('Enter justification text')"
        />
        <FormField :service="service" field="isBase" :label="$t('Do not base')" type="checkbox" />
      </div>
      <div class="d-flex">
        <FormField @input="onChangeProduct" :service="service" field="isInvoiceVAT" :label="$t('Invoice with VAT')" type="checkbox" />
        <FormField
          v-if="service.isInvoiceVAT"
          :service="service"
          field="priceIncludesVAT"
          :label="$t('The price includes VAT')"
          type="checkbox"
          @input="onChangeProduct"
          class="ms-3"
        />
      </div>
      <FormField
        v-if="!service.isInvoiceVAT"
        :service="service"
        field="reasonNoVAT"
        type="select"
        :label="$t('Select the reason for the absence of VAT or enter your own:')"
        :options="reasonNoVatList"
        margin="0"
      />
      <FormField
        v-if="service.reasonNoVAT == 8"
        :service="service"
        field="yourVariantReasonNoVAT"
        :placeholder="$t('Specify your option')"
      />
      <MultiField
        :service="service"
        group="products"
        @input="onChangeProduct"
        class="mt-4"
        :columns="[
          {
            field: 'name',
            label: $t('Product or service'),
            placeholder: $t('Enter the title'),
          },
          {
            field: 'qty',
            label: $t('Quantity'),
            width: '10',
            type: 'number',
          },
          { field: 'unit', width: '15', type: 'select', options: unitList },
          {
            field: 'price',
            label: $t('Price'),
            stepNumber: '0.01',
            width: '15',
            type: 'number',
          },
          {
            field: 'vat',
            label: $t('VAT'),
            width: '15',
            type: 'select',
            options: vatList,
            isShow: !!service.isInvoiceVAT,
          },
          {
            field: 'sum',
            label: $t('Sum'),
            stepNumber: '0.01',
            width: '15',
            type: 'number',
            disabled: true,
          },
        ]"
      />
      <div class="row">
        <div class="col-md-8">
          {{ $t('Total items') }} {{ service.countItems }} {{ $t('for the amount') }}
          {{ !service.isInvoiceVAT || (service.priceIncludesVAT === '1' && service.isInvoiceVAT === '1') ? Str.money(service.countSum) : Str.money(service.countSumVat) }}
          ₽
        </div>
        <div class="col-md-4 fw-bold">  
          <div class="row">
            <div class="col-6 text-end">{{ service.priceIncludesVAT === '1' && service.isInvoiceVAT === '1' ? $t('Total, incl. VAT') : $t('Total') }}:</div>
            <div class="col-6 text-end">{{ Str.money(service.countSum) }} ₽</div>
          </div>
          <div v-if="service.isInvoiceVAT" class="row">
            <div class="col-6 text-end">{{ $t('VAT') }}:</div>
            <div class="col-6 text-end">{{ Str.money(service.countVAT) }} ₽</div>
          </div>
        </div>
      </div>
      <div class="fs-5 fw-bold">
        {{ !service.textSum ? 'Ноль рублей 00 копеек' : service.textSum }}
      </div>
      <FormField
        class="mt-4"
        :service="service"
        :label="$t('Account comment:')"
        field="comment"
        type="textarea"
        :placeholder="$t('Enter comment text')"
      />
      <div class="mt-3 d-flex justify-content-end">
        <Button type="submit" :label="$t('Save and Continue')" class="p-button-lg button-main" icon="pi pi-angle-right" icon-pos="right" />
      </div>
    </Form>
  </div>
</template>

<script>
import axios from 'axios';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Form from '../../../../components/ui/Form.vue';
import FormField from '../../../../components/ui/FormField.vue';
import MultiField from '../../../../components/ui/MultiField.vue';
import Steps from '../../components/Steps.vue';
import RecipientDetails from '../recipientDetails/Create.vue';
import Str from '../../../../utils/Str';

export default {
  components: {
    AutoComplete,
    Button,
    Form,
    FormField,
    MultiField,
    RecipientDetails,
    Steps
  },
  data() {
    return {
      service: this.$store.state.service,
      dadata: null,
      filteredDadata: null,
      Str: Str,
      providerList: [
        { id: 1, name: this.$t('Provider') },
        { id: 2, name: this.$t('Salesman') },
        { id: 3, name: this.$t('Executor') },
        { id: 4, name: this.$t('Licensor') },
      ],
      customerList: [
        { id: 1, name: this.$t('Client') },
        { id: 2, name: this.$t('Buyer') },
        { id: 3, name: this.$t('Customer') },
        { id: 4, name: this.$t('Licensor') },
      ],
      reasonNoVatList: [
        { id: 1, name: this.$t('Not chosen') },
        {
          id: 2,
          name: this.$t(
            'VAT is not charged in connection with the application of the simplified tax system on the basis of Article 346.11 of the Tax Code of the Russian Federation'
          ),
        },
        {
          id: 3,
          name: this.$t(
            'VAT is not charged in connection with the application of PSN on the basis of article 346.43 of the Tax Code of the Russian Federation'
          ),
        },
        {
          id: 4,
          name: this.$t(
            'VAT is not charged in connection with the application of UTII on the basis of article 346.26 of the Tax Code of the Russian Federation'
          ),
        },
        {
          id: 5,
          name: this.$t(
            'VAT is not charged in accordance with sub. 26 p. 2 art. 149 Tax Code of the Russian Federation'
          ),
        },
        {
          id: 6,
          name: this.$t(
            'The operation is not subject to VAT on the basis of article 149 of the Tax Code of the Russian Federation'
          ),
        },
        {
          id: 7,
          name: this.$t('Exempted from VAT on the basis of Article 145 of the Tax Code of the Russian Federation'),
        },
        { id: 8, name: this.$t('Your variant') },
      ],
      vatList: [
        { id: 1, name: this.$t('Without VAT') },
        { id: 2, name: '0%' },
        { id: 3, name: '10%' },
        { id: 4, name: '20%' },
      ],
      unitList: [
        { id: 1, name: this.$t('PC.') },
        { id: 2, name: this.$t('u') },
        { id: 3, name: this.$t('pack.') },
        { id: 4, name: this.$t('set.') },
        { id: 5, name: this.$t('conv. units') },
        { id: 6, name: this.$t('liter') },
        { id: 7, name: this.$t('dm³') },
        { id: 8, name: this.$t('m³') },
        { id: 9, name: this.$t('m²') },
        { id: 10, name: this.$t('kg.') },
        { id: 11, name: this.$t('m.') },
        { id: 12, name: this.$t('pog. m.') },
        { id: 13, name: this.$t('hour') },
      ],
    };
  },
  watch: {
    'service.providerValue': {
      handler(val) {
        if (val.data) {
          let items = val.data;
          this.service.providerName = val.value;
          this.service.providerInn = items.inn;
          this.service.providerKpp = items.kpp;
          this.service.providerAddress = items.address.unrestricted_value || null;
          this.service.providerPhone = items.phones;
          this.service.providerFullName = items.management.name || null;
          this.service.providerPositionMain = items.management.post || null;
        }
      },
      deep: true,
    },
    'service.сustomerValue': {
      handler(val) {
        if (val.data) {
          let items = val.data;
          this.service.сustomerName = val.value;
          this.service.customerInn = items.inn;
          this.service.сustomerAddress = items.address.unrestricted_value;
          this.service.сustomerPhone = items.phones;
        }
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.service.validate();
      if (Object.keys(this.service.errors).length == 0) {
        this.service.step = 2;
        this.$emit('step', 'RecipientDetails');
      }
    },
    searchDadata() {
      setTimeout(() => {
        this.filteredDadata = this.dadata.suggestions.filter((data) => {
          return data;
        });
      }, 250);
    },
    async getDadata(data) {
      let url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';
      var token = '4c5ff6f2a9bd28e43d1dc89e557b94ac23582551';
      var query = data;

      let options = {
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        data: {
          query: query,
        },
      };
      await axios(options)
        .then((response) => response.data)
        .then((result) => {
          this.dadata = result;
        })
        .catch((error) => console.log('error', error));
    },
    onChangeProduct() {
      let rubles = require('rubles').rubles;
      let items = this.service.products;

      this.service.countItems = items.length;

      let sum = 0;
      let sumVat = 0;
      for (let item of items) {
        item.sum = item.qty * item.price;
        let itemSumVat = 0;

        if (item.vat) {
          if (this.service.priceIncludesVAT == '1' && this.service.isInvoiceVAT == '1') {
            if (item.vat == 3) {
              itemSumVat = (item.sum * 10) / (10 + 100);
            } else if (item.vat == 4) {
              itemSumVat = (item.sum * 20) / (20 + 100);
            }
          } else {
            if (item.vat == 1 || item.vat == 2) {
              itemSumVat = 0;
            } else if (item.vat == 3) {
              itemSumVat = (item.sum / 100) * 10;
            } else if (item.vat == 4) {
              itemSumVat = (item.sum / 100) * 20;
            }
          }
        }

        sum += item.sum;
        sumVat += itemSumVat;
      }
      this.service.countSumVat = sumVat + sum;
      this.service.countVAT = sumVat.toFixed(2);
      this.service.countSum = sum.toFixed(2);
      this.service.textSum =
        !this.service.isInvoiceVAT || (this.service.priceIncludesVAT === '1' && this.service.isInvoiceVAT === '1')
          ? rubles(this.service.countSum)
          : rubles(this.service.countSumVat);
    },
  },
  mounted() {
    if (this.service.products) {
      for (let i in this.service.products) {
        this.service.products[i].vat = 3;
        this.service.products[i].unit = 1;
      }
    }
  },
  created() {
    this.service.step = 1;
    this.service.number = 1;
    this.service.provider = 1;
    this.service.сustomer = 2;
    this.service.priceIncludesVAT = '1';
  },
};
</script>
